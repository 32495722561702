import { ref, computed } from "vue";

export default () => {
    
    const post_code_str = ref('');
    const cache_post_code = computed({
        get() {
            let str = localStorage.getItem('cache_post_code') || '[]';
            return JSON.parse(str).some(item => item == post_code_str.value)
        },
        set(val) {
            const arr = localStorage.getItem('cache_post_code') ? JSON.parse(localStorage.getItem('cache_post_code')) : [];

            !arr.some(item => item == val) && arr.push(val);
            localStorage.setItem('cache_post_code', JSON.stringify(arr))
        }
    });

    return {
        post_code_str,
        cache_post_code,
    }
}